<script>
import Layout from "../../layouts/main.vue";

import * as signalR from "@microsoft/signalr";
import { setupConnection } from "./js/from-server";

import Title from "./_Title.vue";
import Profiles from "./_Profiles.vue";
import Messages from "./_Messages.vue";
import Send from "./_Send.vue";
import Donate from "./_Donate.vue";

import Hls from 'hls.js';
import axios from 'axios';

var wsconn = new signalR.HubConnectionBuilder()
  .withUrl(process.env.VUE_APP_SIGNALR_URL, signalR.HttpTransportType.WebSockets)
  .configureLogging(signalR.LogLevel.None).build();

export default {
  data() {
    return {
      data: {
        id: '',
      },
      cssPlayButtonClass: 'ri-play-line',
      cssMuteButtonClass: 'ri-volume-up-line',
      classMuteOn: 'ri-volume-mute-line',
      classMuteOff: 'ri-volume-up-line',
      wsconnSetup: false,
      moderatorConnectionId: null,
      hslLoaded: false,
      streamActive: false,
      streamConfigAllowChat: true,
      streamConfigAllowDonations: false,
      listenersCounter: 0,
    };
  },
  props: {
  },
  components: {
    Title,
    Layout,
    Profiles,
    Messages,
    Send,
    Donate,
  },
  mounted() {
    this.ifNeedWsconnSetup();
    this.loadingEpisode();
    this.signalRCreate();
  },
  methods: {
    loadingEpisode() {
      axios.get('/api/episode/simple/' + this.$route.params.id, { withCredentials: true })
        .then((response) => {
          this.data = response.data;
        })
        .catch(function () {
        });
    },
    loadingStream() {
      var audioControl = document.getElementById('player');
      var vm = this;
      audioControl.onloadeddata = function () {
        console.log("Play stream");
        try {
          audioControl.play();
          vm.cssPlayButtonClass = 'ri-pause-line';
          vm.hslLoaded = true;
          console.log("Play success");
        }
        catch (error) {
          console.error("Play error", error)
          vm.cssPlayButtonClass = 'ri-play-line';
        }
      };

      var streamSrc = process.env.VUE_APP_MEDIAMTX_HLS_URL + this.$route.params.session + '/index.m3u8';
      console.log("Stream HLS url", streamSrc);
      console.log("Hls.isSupported()", Hls.isSupported());
      if (Hls.isSupported()) {
        console.log('Setup HLS instance...');
        var hls = new Hls();
        hls.loadSource(streamSrc);
        hls.attachMedia(audioControl);
        audioControl.load();
      }
      else if (audioControl.canPlayType('application/vnd.apple.mpegurl')) {
        console.log('Set SRC for audio...');
        audioControl.src = streamSrc;
      }
    },
    signalRCreate() {
      var vm = this;
      wsconn.start().then(() => {
        console.log("SignalR: Connected");

        var id = this.$route.params.session;
        wsconn.invoke("Guest", id)
          .then(() => {
            console.log("Connect as guest.");
            vm.streamActive = true;
          })
          .catch((error) => {
            console.error("Connect as guest: ", error);
            vm.streamActive = false;
          });

      }).catch((error) => {
        console.error(error);
        vm.streamActive = false;
      });
    },
    ifNeedWsconnSetup() {
      console.log("SignalR current state", wsconn.state);
      if (this.wsconnSetup === false) {
        console.log("Need to setup wsconn...");
        setupConnection(wsconn, this);
        this.wsconnSetup = true;
      }
    },
    sendMessage(form) {
      var isGuest = true;
      wsconn.invoke("SendMessage", form.message, isGuest).catch((error) => {
        console.error(error);
      });
    },
    playStream() {
      if(this.hslLoaded) {
        console.log('Current time', this.$refs.player.currentTime);
        console.log('Duration', this.$refs.player.duration);

        if (this.cssPlayButtonClass === 'ri-play-line') {
          if (this.$refs.player.duration > 0)
            this.$refs.player.currentTime = this.$refs.player.duration;
          this.$refs.player.play();
          this.cssPlayButtonClass = 'ri-pause-line';
        }
        else {
          this.$refs.player.pause();
          this.cssPlayButtonClass = 'ri-play-line';
        }
      } else {
        this.loadingStream();
      }
    },
    stopStream() {
      this.streamActive = false;
      this.$refs.player.src = '';
      this.$refs.player.pause();
      wsconn.stop();
    },
    sendSystemMessageToChat(message, attach = false) {
      var time = new Date().toLocaleTimeString();
        var m = {
          align: 'left',
          name: '',
          image: '',
          message: message,
          time: time,
          system: true,
        }
        this.$refs.pnlMessages.addMessage(m, attach, true);
    },
    muteStream() {
      if (this.cssMuteButtonClass === this.classMuteOn) {
        this.$refs.player.muted = false;
        this.cssMuteButtonClass = this.classMuteOff;
      }
      else {
        this.$refs.player.muted = true;
        this.cssMuteButtonClass = this.classMuteOn;
      }
    }
  },
  computed: {
    getEpisodeId: function () {
      if (this.data && this.data.episode && this.data.episode.id)
        return this.data.episode.id;
      return this.$route.params.id;
    },
  }
};
</script>

<template>
  <Layout>

    <div class="card">
      <div class="card-body">

        <div class="row">

          <div class="col-12 col-md-4 col-sm-12 col-xs-12">

            <Title :title="data.episode.title" :listeners="listenersCounter" :short="data.episode.short" :url="data.episode.urlAvatar" :is-server-side="false" v-if="data.episode"></Title>

            <div class="text-center pt-4 pb-4">

              <audio id='player' ref="player" preload='metadata' autoplay="autoplay" controls type='application/x-mpegURL' class="d-none"></audio>

              <div v-if="streamActive" data-aos="flip-right" data-aos-duration="2000">
                <b-button variant="light" pill size="lg me-2" @click="playStream">
                  <i class="fs-1" :class="cssPlayButtonClass"></i>
                </b-button>
                <b-button variant="primary" pill size="md me-2" @click="muteStream">
                  <i :class="cssMuteButtonClass"></i>
                </b-button>
                <b-button variant="light" pill size="md me-2" class="btn-label waves-effect waves-light" tooltip="Listeners">
                  <i class="ri-broadcast-line label-icon align-middle fs-16 me-2"></i> {{ listenersCounter }}
                </b-button>
              </div>

            </div>

            <Profiles ref="pnlProfiles"></Profiles>

            <Donate ref="pnlDonations" v-if="streamConfigAllowDonations" :session="this.$route.params.session" :episode="this.$route.params.id"></Donate>

          </div>

          <div class="col-12 col-md-8 col-sm-12 col-xs-12">
            <div class="d-lg-flex">
              <div class="w-100 position-relative">
                <div class="user-chat position-relative">

                  <Messages ref="pnlMessages" :imModerator="false"></Messages>
                  <Send ref="pnlSend" @send-message="sendMessage"></Send>

                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

  </Layout>
</template>
